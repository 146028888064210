import request from '@/utils/request'

export function getItemsByActiveCategory() {
  return request({
    url: '/api/v1/manager/item/by-category',
    method: 'get'
  })
}

export function getItemMainArt() {
  return request({
    url: '/api/v1/manager/item/by-id/' + process.env.VUE_APP_MAIN_ART_ID,
    method: 'get'
  })
}
