<template>
  <section class="section section-md" style="margin-top: 120px">
    <div class="container">
      <h3><span class="text-line">need help? contact me.</span></h3>
      <div class="row row-30 row-offset-2">
        <div class="col-md-6">
          <p>If you have any questions, just fill in the contact form, and i will answer you shortly.</p>
          <ul class="list-sm">
            <li><a class="link link-btn link-btn-xs" href="mailto:elensoul@bk.ru">Me elensoul@bk.ru</a></li>
            <li><a class="link link-btn link-btn-xs" href="mailto:like.no.one@mail.ru">Manager Irina like.no.one@mail.ru</a></li>
          </ul>
        </div>
        <div class="col-md-6 mt-xl-n3 mt-xxl-n4">
          <!--RD Mailform-->
          <form class="rd-mailform text-start" data-form-output="form-output-global" data-form-type="contact" method="post" action="bat/rd-mailform.php">
            <div class="form-wrap">
              <div class="form-text">Name</div>
              <label class="form-label" for="contact-name">e.g.: Emma McCoy</label>
              <input id="contact-name" class="form-input" type="text" name="name" data-constraints="@Required">
            </div>
            <div class="form-wrap">
              <div class="form-text">E-Mail</div>
              <label class="form-label" for="contact-email">e.g.: info@demolink.org</label>
              <input id="contact-email" class="form-input" type="email" name="email" data-constraints="@Required @Email">
            </div>
            <div class="form-wrap">
              <div class="form-text">Subject</div>
              <label class="form-label" for="contact-subject">The subject of your message</label>
              <input id="contact-subject" class="form-input" type="text" name="subject" data-constraints="@Required">
            </div>
            <div class="form-wrap">
              <div class="form-text form-text-textarea">Message</div>
              <label class="form-label" for="contact-message">Write your message here...</label>
              <textarea id="contact-message" class="form-input" name="message" data-constraints="@Required" />
            </div>
            <div class="form-button d-grid">
              <button class="btn btn-primary btn-ujarak" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Contacts',
  components: { },
  props: {
  },
  mounted() {
    window.init()
  }
}

</script>
