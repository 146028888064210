<template>
  <header class="section page-header">
    <!--RD Navbar-->
    <div class="rd-navbar-wrap rd-navbar-wrap-absolute">
      <nav
        class="rd-navbar"
        data-layout="rd-navbar-fixed"
        data-sm-layout="rd-navbar-fixed"
        data-md-layout="rd-navbar-fixed"
        data-lg-layout="rd-navbar-fixed"
        data-xl-layout="rd-navbar-static"
        data-md-device-layout="rd-navbar-fixed"
        data-lg-device-layout="rd-navbar-fixed"
        data-xl-device-layout="rd-navbar-static"
        data-xxl-device-layout="rd-navbar-static"
        data-lg-stick-up-offset="46px"
        data-xl-stick-up-offset="46px"
        data-xxl-stick-up-offset="46px"
        data-lg-stick-up="true"
        data-xl-stick-up="true"
        data-xxl-stick-up="true"
      >
        <div class="rd-navbar-collapse-toggle rd-navbar-fixed-element-1" data-rd-navbar-toggle=".rd-navbar-collapse"><span /></div>
        <div class="rd-navbar-aside-outer rd-navbar-collapse">
          <div class="rd-navbar-aside">
            <div class="rd-navbar-info">
              <div class="rd-navbar-info-text">Follow me:</div>
              <a class="rd-navbar-info-icon link-light icon mdi mdi-instagram" href="https://instagram.com/elensoul_art"> ART</a>
              <a class="rd-navbar-info-icon link-light icon mdi mdi-instagram" href="https://instagram.com/elen_soul_tattoo"> TATTOO</a>
            </div>
          </div>
        </div>
        <div class="rd-navbar-main-outer">
          <div class="rd-navbar-main">
            <!--RD Navbar Panel-->
            <div class="rd-navbar-panel">
              <!--RD Navbar Toggle-->
              <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span /></button>
              <!--RD Navbar Brand-->
              <div class="rd-navbar-brand">
                <router-link class="ivu-menu-item" to="" exact>
                  <a class="brand">
                    <img class="brand-logo-dark" :src=" 'images/logo.png'" alt="" width="175">
                    <img class="brand-logo-light" :src="'images/logo.png'" alt="" width="171">
                  </a>
                </router-link>
              </div>
            </div>
            <div class="rd-navbar-nav-toggle mdi mdi-menu" data-multitoggle=".rd-navbar-nav-wrap"><span /></div>
            <div class="rd-navbar-nav-wrap">
              <div class="rd-navbar-close-toggle mdi mdi-close" data-multitoggle=".rd-navbar-nav-wrap"><span /></div>
              <div class="rd-navbar-nav-wrap-inner">
                <ul class="rd-navbar-nav">
                  <router-link
                    v-for="menuItem in menuItems"
                    v-slot="{ href, navigate }"
                    :key="menuItem"
                    :to="{ name: menuItem }"
                    active-class="active"
                    custom
                  >
                    <li class="rd-nav-item"><a class="rd-nav-link" :href="href" data-multitoggle=".rd-navbar-nav-wrap" @click="navigate">{{ menuItem }}</a></li>
                  </router-link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
  },
  data() {
    return {
      menuItems: [
        'Main',
        'Bio',
        'Gallery',
        'Contacts'
      ]
    }
  }
}
</script>
