<template>
  <section class="section section-lg bg-default">
    <div class="container">
      <div class="row">
        <div class="col-md-10 col-lg-7 offset-md-2 offset-lg-4">
          <h3><span class="text-line">about elen</span></h3>
          <div class="big">"The main concept of my art is reflecting my own feelings and psychological state. Reflecting myself.
            Being a very emotional and empathic person, it's necessary for me as air to breathe.
            And surrealism for me is the most appropriate style to mirror my thoughts. I'm experimenting with different
            art techniques as well as using autobiographical references. Each viewer of my art finds something unique – they
            see their own associations and thoughts. It's always interesting for me to find out how my art opened up in
            the soul of another person. I think this is what art is for – to resonate with a person, sometimes make them
            think about something or give them a push. Art is more than just looking at a pretty picture."</div>
          <div class="blurb-event-title h4 big">
            <a href="https://www.tattooartist.pl/artykuly/wywiady/elen-soul-z-rocknroll-tattoo-and-piercing-studio-warszawa/amp/">Last interview</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  props: {
  }
}
</script>
