import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'

import Main from '@/components/main/Main'
import Bio from '@/components/about/About'
import Gallery from '@/components/gallery/Gallery'
import Contacts from '@/components/contacts/Contacts'

Vue.use(Router)
Vue.config.productionTip = false

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Main',
      component: Main
    },
    {
      path: '/bio',
      name: 'Bio',
      component: Bio,
      props: true
    },
    {
      path: '/gallery',
      name: 'Gallery',
      component: Gallery,
      props: true,
      meta: {
        reload: false
      }
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: Contacts,
      props: true
    }
  ]
})

new Vue({
  router: router,
  watch: {
    '$route'(to) {
      if (to.meta.reload === true) {
        window.location.reload()
      }
    }
  },
  render: h => h(App)
}).$mount('#app')
