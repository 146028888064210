<template>
  <section class="section section-lg bg-default">
    <div class="container isotope-wrap">
      <div class="row row-30">
        <div class="col-md-4 col-lg-3" />
        <div class="col-md-8 col-lg-9">
          <vue-instagram token="IGQVJYVEhreHFFbURWWkVwN2NnRXhXaEhaWWFFOWluYTFKSFpaaHhoRHJzSnU4RlVWMUF3RW5RLWl2cVRkY3ZALM2hseVozZA1pNVEJ5ZA0FyRzhRcTlxVVhfMkF0UWlEMWJYaHptU3VtV0dqXzV6X3JTTwZDZD" :count="5">
            <template slot="feeds" slot-scope="props">
              <li class="fancy-list"> {{ props.feed.link }} </li>
            </template>
            <template slot="error" slot-scope="props">
              <div class="fancy-alert"> {{ props.error.error_message }} </div>
            </template>
          </vue-instagram>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { getCategories } from '@/api/category'
import { getItemMainArt } from '@/api/item'
import VueInstagram from 'vue-instagram'

export default {
  name: 'Gallery',
  components: {
    VueInstagram
  },
  props: {
  },
  data() {
    return {
      categories: [],
      item: {}
    }
  },
  created() {
    this.getCategories()
    this.getItemMainArt()
  },
  methods: {
    getCategories() {
      getCategories().then(response => {
        this.categories = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    getItemMainArt() {
      getItemMainArt().then(response => {
        this.item = response.data
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>
