<template>
  <!-- about art gallery-->
  <section class="section section-lg bg-default">
    <div class="container">
      <div class="row row-30 align-items-center">
        <div class="col-md-5 col-xl-4 offset-xl-1">
          <div class="image-responsive"><img :src="'images/elen.jpg'" alt="">
          </div>
        </div>
        <div class="col-md-7 col-lg-6 offset-lg-1">
          <h3><span class="text-line">about elen soul</span></h3>
          <p>Elen Soul is from the Caucasus. She was born and raised in Nalchik (Kabardino-Balkarian Republic, Russia). She became interested in drawing from early childhood, studying independently all her school years. This helped her to enter the university at the Design Faculty and graduate from it with honors.</p>

          <p>Elen studied at school No. 5 from the 1st to 11th grade.
            After finishing school, she entered the North-Caucasus Federal University (NCFU), where she began to try herself in tattooing.</p>

          <p>After graduating from university, Elen lived and worked in Moscow. Since 2012, she has been engaged in artistic tattooing at a professional level.</p>

          <p>Elen has taken part in many international tattoo conventions and has great experience working in Europe.</p>

          <p>She is a winner of the Germany and Poland Tattoo Conventions.
            She was the ambassador of the American inks and the German Tattoo Pharma companies.
            Then she signed a contract with some companies, and now she is the ambassador of Inkjecta – a top manufacturer of tattoo machines, Sullen – an American clothing brand; they released several merch lines made in collaboration), and the American Tattoodo portal.
            Against the background of success and demand in the tattoo industry, Elen moved to Germany, where she has been living and working as a tattoo master for several years.</p>

          <p>Then she returned to Russia for a short period of time and moved to Poland.</p>

          <p>At the moment, Elen Soul lives and works in Warsaw, Poland.
            Since mid-2020, she has returned to painting and currently combines tattooing and fine art.</p>

          <p>Also, Elen has been selling her oil paintings since 2020. A lot of her works have already been sent around the world. She also does graphics for her own pleasure.</p>

          <p>The main direction of her art is surrealism.</p>

          <p>"The main concept of my art is reflecting my own feelings and psychological state. Reflecting myself.
            Being a very emotional and empathic person, it's necessary for me as air to breathe.
            And surrealism for me is the most appropriate style to mirror my thoughts. I'm experimenting with different
            art techniques as well as using autobiographical references. Each viewer of my art finds something unique – they
            see their own associations and thoughts. It's always interesting for me to find out how my art opened up in
            the soul of another person. I think this is what art is for – to resonate with a person, sometimes make them
            think about something or give them a push. Art is more than just looking at a pretty picture."</p>

        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Bio',
  components: { },
  props: {
  },
  mounted() {
    window.init()
  }
}

</script>
