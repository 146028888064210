<template>
  <div>
    <Intro />
    <About />
    <Gallery />
  </div>
</template>

<script>
import Intro from './kind/Intro'
import About from './kind/About'
import Gallery from './kind/Gallery'

export default {
  name: 'Main',
  components: { Intro, About, Gallery },
  props: {
  },
  mounted() {
    window.init()
  }
}
</script>
