<template>
  <div>
    <Header />
    <div id="app"><router-view /></div>
    <Footer />
  </div>
</template>

<script>

import Header from './components/header/Header'
import Footer from './components/footer/Footer'

export default {
  name: 'App',
  components: {
    Footer,
    Header
  }
}
</script>
