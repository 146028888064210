<template>
  <footer class="section footer-classic">
    <div class="container">
      <div class="footer-inner">
        <div class="row row-50 justify-content-center justify-content-lg-start">
          <div class="col-md-10 col-xl-7 offset-lg-1">
            <!--Brand--><a class="brand" href="/"><img
                          class="brand-logo-dark"
                          :src="'images/logo.png'"
                          alt=""
                          width="175"
                          height="35"
                        ><img
                          class="brand-logo-light"
                          :src="'images/logo.png'"
                          alt=""
                          width="171"
                          height="22"
                        ></a>
            <div class="row row-30 row-footer">
              <div class="col-sm-5 col-md-6 col-xl-5">
                <ul class="list-sm">
                  <router-link
                    v-for="menuItem in menuItems"
                    v-slot="{ href, navigate }"
                    :key="menuItem"
                    :to="{ name: menuItem }"
                    custom
                  >
                    <li><a class="link link-btn link-btn-sm" :href="href" @click="navigate">{{ menuItem }}</a></li>
                  </router-link>
                </ul>
                <div class="icon-group">
                  <a class="icon link-dark icon-md mdi mdi-instagram" href="https://instagram.com/elensoul_art"> ART</a>
                  <a class="icon link-dark icon-md mdi mdi-instagram" href="https://instagram.com/elen_soul_tattoo"> TATTOO</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-10 col-lg-9 offset-lg-1">
            <div class="row row-15 justify-content-between align-items-center">
              <div class="col-auto">
                <h1>let it burn</h1>
              </div>
              <div class="col-auto">
                <svg width="271" height="16" viewBox="0 0 271 16" fill="none">
                  <path
                    d="M270.707 8.70713C271.098 8.31661 271.098 7.68344 270.707 7.29292L264.343 0.928955C263.953 0.538431 263.319 0.538431 262.929 0.928955C262.538 1.31948 262.538 1.95264 262.929 2.34317L268.586 8.00002L262.929 13.6569C262.538 14.0474 262.538 14.6806 262.929 15.0711C263.319 15.4616 263.953 15.4616 264.343 15.0711L270.707 8.70713ZM-8.74228e-08 9L270 9.00002L270 7.00002L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="#931900"
                  />
                </svg>
              </div>
            </div>
            <p class="rights"><span>&copy;&nbsp; </span><span
              class="copyright-year"
            /><span>&nbsp;</span><span>Elen Soul</span></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
  },
  data() {
    return {
      menuItems: [
        'Main',
        'Bio',
        'Gallery',
        'Contacts'
      ]
    }
  }
}
</script>
