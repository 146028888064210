<template>
  <!-- Intro-->
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-xl-8 offset-md-1 offset-lg-2">
          <div class="box-intro" style="background-image:url('images/intro.jpg')">
            <h4 class="box-intro-subtitle">elen soul</h4>
            <h1 class="box-intro-title"><span>art</span><span>gallery</span><span>of soul</span></h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Intro',
  props: {
  }
}
</script>
