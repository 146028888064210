<template>
  <section class="section section-lg bg-default">
    <div class="container">
      <div class="row row-30">
        <h3 class="white-100"><span class="text-line">Look</span></h3>
        <div class="col-lg-12">
          <LightGallery
            :images="images"
            :index="index"
            :disable-scroll="true"
            @close="index = null"
          />
          <div class="row row-30 row-lg-60">
            <div
              v-for="(thumb, thumbIndex) in imagesThumb"
              :key="thumbIndex"
              class="col-lg-4 col-md-6"
              @click="index = thumbIndex"
            >
              <div class="product">
                <img :src="thumb">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { getCategories } from '@/api/category'
import { getItemsByActiveCategory } from '@/api/item'
import { LightGallery } from 'vue-light-gallery'

export default {
  name: 'Gallery',
  components: {
    LightGallery
  },
  props: {
  },
  data() {
    return {
      categories: [],
      items: [],
      images: [],
      imagesThumb: [],
      index: null
    }
  },
  created() {
    this.getCategories()
    this.getItemsByActiveCategory()
  },
  mounted() {
    window.init()
  },
  methods: {
    getCategories() {
      getCategories().then(response => {
        this.categories = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    getItemsByActiveCategory() {
      getItemsByActiveCategory().then(response => {
        this.items = response.data
        for (let i = 0; i < this.items.length; i++) {
          this.images.push({ title: this.items[i].details, url: '/public/images/' + this.items[i].images[0].full })
          this.imagesThumb.push('/public/images/' + this.items[i].images[0].preview)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onInit: () => {
      console.log('lightGallery has been initialized')
    },
    onBeforeSlide: () => {
      console.log('calling before slide')
    }
  }
}
</script>
<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css');
</style>
